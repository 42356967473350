import React, { useState } from 'react';

// Image paths for all timer attributes
const imagePaths = {
    timerColor: {
      'Black': 'timer.jpg',
    }
};

const Timer = ({ onAddItem }) => {
  const [timerColor, setTimerColor] = useState('Black');

  return (
    <div>
      <label htmlFor="quantity">Quantity:</label><br />
      <input type="number" id="quantity" min="1" defaultValue="1" /><br /><br />

      <div className="selection timer-color-selection"> 
        <div>
          <label htmlFor="timerColor">Timer colour:</label><br />
          <select id="timerColor" value={timerColor} onChange={e => setTimerColor(e.target.value)}>
            <option value="Black">Black</option>
          </select>
        </div>

        {/* Display the image based on the selected color */}
        {timerColor && (
          <div><img src={imagePaths.timerColor[timerColor]} alt={`Timer in ${timerColor}`} /></div>
        )}
      </div>
      <br />

      <button onClick={() => 
        onAddItem({ 
          quantity: parseInt(document.getElementById('quantity').value) || 1, 
          timerColor
        }) 
      }>
        Add to Cart
      </button>
    </div>
  );
};

export default Timer;
