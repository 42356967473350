import Home from './Home';
import Buy from './Buy';
import Impressum from './Impressum';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <nav className="navbar navbar-expand navbar-dark">
        <div className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
              Information
            </NavLink>
          </li>
          <span className="nav-link">&nbsp;-&nbsp;</span>
          <li className="nav-item">
            <NavLink to="/buy" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
              Online Shop
            </NavLink>
          </li>
        </div>
      </nav>
    <div className="container pt-3">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
