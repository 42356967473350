import React from 'react';

const Impressum = () => (
    <div>
        <h2>Impressum</h2>
        <table>
            <tr><th>Name</th></tr><tr><td>Christian Haas</td></tr>
            <tr><th>Standort der Gewerbeberechtigung bzw. volle geografische Anschrift</th></tr><tr><td>Jüptnergasse 14, 1220 Wien, Österreich</td></tr>
            <tr><th>Unternehmensgegenstand</th></tr><tr><td>Fertigung von Tischfußballequipment</td></tr>
            <tr><th>Kontaktdaten (Tel, E-Mail)</th></tr><tr><td>E-Mail: office@tablefootball.shop</td></tr>
            <tr><th>UID-Nummer</th></tr><tr><td>Kleinunternehmerregelung</td></tr>
            <tr><th>Mitgliedschaften bei der Wirtschaftskammerorganisation</th></tr><tr><td>Mitglied der WKW</td></tr>
            <tr><th>Anwendbare Rechtsvorschriften und Zugang dazu</th></tr><tr><td>Berufsrecht: Gewerbeordnung: http://www.ris.bka.gv.at</td></tr>
            <tr><th>Aufsichtsbehörde/Gewerbebehörde</th></tr><tr><td>Magistrat der Stadt Wien, Magistratisches Bezirksamt für den 22. Bezirk</td></tr>
            <tr><th>Angaben zur Online-Streitbeilegung</th></tr><tr><td>Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</td></tr>
        </table>
    </div>
);

export default Impressum;
